<template>
    <div class="card p-2" v-loading="isLoading">
        <div class="text-left d-flex flex-column flex-md-row align-items-center align-items-md-center">
            <div class="pl-0 pl-md-2 flex-fill mb-2 mb-md-0">
                <div class="d-flex flex-column align-items-center align-items-md-start">
                    <h4 class="mb-1"><strong>{{title}}</strong></h4>
                    <h5 class="mb-0"><i class="el-icon-date text-primary"></i> {{dateView | SimplyDate1}}</h5>
                </div>
            </div>
            <div class="pr-2">
                <div class="mt-0">
                    <el-button type="primary" size="small"
                               @click="addDate(-1)">
                        <i class="el-icon-arrow-left"></i> Prev
                    </el-button>
                    <el-date-picker v-model="dateView"
                                    @change="debounceFnc"
                                    type="date"
                                    size="small"
                                    :format="$elDatePickerFormat"
                                    style="width: 10rem;"
                                    placeholder="Pick a day.."
                                    class="mr-2 ml-2" />
                    <el-button type="primary" size="small"
                               @click="addDate(1)">
                        Next <i class="el-icon-arrow-right"></i>
                    </el-button>

                    <el-button type="primary" size="small" @click="getData">
                        <i class="el-icon-check"></i> Execute
                    </el-button>
                </div>
            </div>
        </div>
        <highcharts class="mt-2 mr-1" :options="chartOptions.options"></highcharts>
    </div>
</template>

<script>
    import chartDataApi from '@/api/common/chartData';
    import { debounce } from 'vue-debounce';
    import { Chart } from 'highcharts-vue';
    import {
        barChartHoz
    } from "@/constant/dashboardChartOptions";
    import moment from 'moment';

    export default {
        components: {
            highcharts: Chart,
        },
        props: {
            title: {
                type: String,
                default: "",
            }
        },
        watch: {
            dateView(newValue) {
                this.getData();
            },
        },

        data() {
            return {
                debounceFnc: debounce(() => {

                }, 300),
                isLoading: false,
                listData: [],
                dateView: moment(new Date()).add(-1, 'days'),
                dateViewUtc: null,
                chartOptions: {
                    options: Object.assign(JSON.parse(JSON.stringify(barChartHoz)), {
                        chart: {
                            height: 600,
                            type: 'bar'
                        },
                        legend: { enabled: false },
                        xAxis: {
                            categories: [],
                            title: {
                                text: null
                            },
                            labels: {
                                useHTML: true,
                                animate: true,
                                formatter: function () {
                                    var value = this.value;
                                    return value;
                                }
                            }
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: 'Total GB transfer out (GB)',
                                align: 'high'
                            },
                            labels: {
                                overflow: 'justify'
                            }
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                        return `${this.y} GB`;
                                    }
                                }
                            }
                        },
                        tooltip: {
                            valueSuffix: ' GB'
                        },
                        series: [{
                            name: 'Total GB transfer out',
                            data: []
                        }]
                    }),
                }
            };
        },
        mounted() {
            this.getData();
            this.debounceFnc = debounce(() => {
                this.getData();
            }, 60000);
        },
        methods: {
            getData() {
                this.isLoading = true;
                this.dateViewUtc = moment(this.dateView).utc();
                chartDataApi.statisticS3TransferOutByDate(this.dateView).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.listData = response.data.data;
                        this.chartOptions.options.xAxis.categories = [];
                        this.chartOptions.options.xAxis.categories = response.data.data.map(x => x.USER_NAME);
                        this.chartOptions.options.series[0].data = response.data.data.map(x => (x.TOTAL_GB));
                        this.chartOptions.options.chart.height = this.chartOptions.options.xAxis.categories.length * 25;
                        this.isLoading = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            this.$message({
                                message: response.data.message || this.$lang.common.error,
                                type: 'warning'
                            });
                        }
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });
            },
            addDate(offsetDay) {
                this.dateView = moment(this.dateView).add(offsetDay, 'days');
                this.getData();
            },
        }
    }
</script>